// Import libraries
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import Img from 'gatsby-image';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh - 15rem);

    & .content {
        display: grid;
        grid-template-rows: 1fr 6rem;
        align-items: center;
        justify-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);

        & .container {
            display: block;
            padding: 0 var(--padding-right) 0 var(--padding-left);
            width: 100%;
            max-width: var(--max-width);
        }

        & .inner h1 {
            font-size: calc(40px + (80 - 40) * ((100vw - 320px) / (2200 - 320)));
            line-height: 1.2;
            color: #fff;
            
            & .primary {
                color: var(--color-primary);
            }

            @media only screen and (min-width: 1600px) {
                font-size: 55px;
            }

            @media only screen and (min-width: 1900px) {
                font-size: 70px;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            outline: 0;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledBusinessServices = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5rem;
    grid-row-gap: 5rem;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);

    @media only screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 400px) {
        grid-template-columns: 1fr;
    }

    & .service {
        display: grid;
        grid-template-rows: 110px max-content;
        grid-row-gap: 1rem;
        justify-items: center;
        text-align: center;

        &-icon {

        }

        h3 {
            font-size: 2rem;
            font-weight: 600;
        }

        p {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }
    }
`;

const StyledOurFeatures = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media only screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }

    & .feature.content {
        display: grid;
        grid-template-rows: repeat(3, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: var(--padding-right) var(--padding-right) var(--padding-left) var(--padding-left);
        background-color: var(--color-background-2);

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .feature__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }

        & .content-link {
            justify-self: start;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;

            & span {
                margin-right: 10px;
            }
        }

        & .content-link:link,
        & .content-link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        & .content-link:hover,
        & .content-link:focus {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        & .content-link:active {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }

    & .container.image {
        & .gatsby-image-wrapper {
            height: 100%;
        }

        @media only screen and (max-width: 800px) {
            &--2 {
                grid-row: 4 / 5;
            }

            &--4 {
                grid-row: 8 / 9;
            }
        }
    }
`;

const StyledActionBanner = styled.div`
    & .content {
        display: grid;
        grid-template-columns: 75% 1fr;
        grid-row-gap: 1rem;
        padding: var(--padding-right);
        background-color: var(--color-secondary);
        color: #fff;
        
        @media only screen and (max-width: 950px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, min-content);
            grid-row-gap: 5rem;
        }

        & h1 {
            font-size: 5rem;
            font-weight: 100;
            line-height: var(--line-height-body-h1);
        }

        & h2 {
            font-size: 5rem;
            font-weight: 400;
            line-height: var(--line-height-body-h2);
            color: var(--color-primary);

            & span {
                font-size: 2rem;
            }

            @media only screen and (max-width: 950px) {
                margin-top: -4rem;
            }
        }

        &-link {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
            align-self: center;
            justify-self: center;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;
            text-decoration: none;

            & span {
                margin-right: 10px;
            }

            @media only screen and (max-width: 950px) {
                grid-column: 1 / -1;
                grid-row: 3 / 4;
                justify-self: start;
            }
        }

        &-link:link,
        &-link:visited {
            color: currentColor;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: currentColor;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        &-link:active {
            text-decoration: none;
            color: currentColor;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }
`;


// Create component
const ZakelijkPagina = () => {
    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "banner/zakelijk/Bedrijfsadministratie.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }



            service1Icon: file(relativePath: { eq: "services/zakelijk/Bedrijfsadministratie.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }

            service2Icon: file(relativePath: { eq: "services/zakelijk/Jaarrekening.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }

            service3Icon: file(relativePath: { eq: "services/zakelijk/Salarisadministratie.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }

            service4Icon: file(relativePath: { eq: "services/zakelijk/Fiscaal-advies.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }

            service5Icon: file(relativePath: { eq: "services/zakelijk/Begeleiding-startende-ondernemers.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }

            service6Icon: file(relativePath: { eq: "services/zakelijk/Second-opinion.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }



            featuredService1Image: file(relativePath: { eq: "services/zakelijk/featured/Administratie-voor-bedrijven.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            featuredService2Image: file(relativePath: { eq: "services/zakelijk/featured/Salarisadministratie.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            featuredService3Image: file(relativePath: { eq: "services/zakelijk/featured/Fiscale-advisering.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            featuredService4Image: file(relativePath: { eq: "services/zakelijk/featured/Begeleiding-startende-ondernemers.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);


    // Array of services
    const services = [
        {
            id: 1,
            title: 'Bedrijfsadministratie',
            icon: data.service1Icon.childImageSharp.fixed
        },
        {
            id: 2,
            title: 'Jaarrekening',
            icon: data.service2Icon.childImageSharp.fixed
        },
        {
            id: 3,
            title: 'Salarisadministratie',
            icon: data.service3Icon.childImageSharp.fixed
        },
        {
            id: 4,
            title: 'Fiscaal Advies',
            icon: data.service4Icon.childImageSharp.fixed
        },
        {
            id: 5,
            title: 'Begeleiding van Startende Ondernemers',
            icon: data.service5Icon.childImageSharp.fixed
        },
        {
            id: 6,
            title: 'Second Opinion',
            icon: data.service6Icon.childImageSharp.fixed
        }
    ];


    // Handle scrolling down to next section on page
    const handleScrollDown = () => {
        // Get header height difference between static header height and scollling header height and get banner height
        const headerHeightDifference = (parseFloat(window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size')) * 15) - (parseFloat(window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size')) * 10);
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        

        // Scroll to place underneath banner
        window.scrollTo({
            top: (bannerHeight + headerHeightDifference),
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO
                title="Zakelijk"
                description="Wij verzorgen graag uw bedrijfsadministratie en kunnen u voorzien met allerlei andere fiscale diensten."
            />
            <Layout>
                <StyledBanner image={data.bannerImage} className="banner">
                    <div className="content">
                        <div className="inner container">
                            <h1><span className="primary">Vrijblijvend</span> met een frisse blik naar <span className="primary">uw bedrijf</span> laten kijken?</h1>
                        </div>

                        <div className="container">
                            <div className="scroll-down-button" role="button" tabIndex={0} onClick={handleScrollDown} onKeyDown={handleScrollDown}>
                                <FontAwesomeIcon icon={faArrowDown} className="icon" />
                            </div>
                        </div>
                    </div>
                </StyledBanner>

                <StyledBusinessServices>
                    {services.map(service => (
                        <div className="service" key={`service-${service.id}`}>
                            <Img fixed={service.icon} alt="" className="service-icon" />
                            <h3>{service.title}</h3>
                        </div>
                    ))}
                </StyledBusinessServices>

                <StyledActionBanner>
                    <div className="content">
                        <h1>Bedrijfsadministratie</h1>
                        <h2>Al vanaf &euro;60 per maand <span>(excl. BTW)</span></h2>

                        <Link to="/contact" className="content-link">
                            <span>Neem contact op</span>
                            <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                        </Link>
                    </div>
                </StyledActionBanner>
                
                <StyledOurFeatures>
                    <div className="feature content">
                        <h1>Bedrijfsadministratie</h1>

                        <p className="feature__summary">Uw bedrijf staat bij ons administratiekantoor centraal. Wij passen daarom onze diensten geheel aan op uw onderneming. Bij ons kunt u uw bedrijfsadministratie gedeeltelijk of geheel uit handen geven. Zo kunnen ZZP'ers al vanaf &euro;60 p/m excl BTW bij ons terecht. Ook hebben wij pakketten voor midden- en klein bedrijven. Wij werken met gebruiksvriendelijke en effici&euml;nte software zodat alles altijd Up2Date blijft.</p>

                        <Link to="/contact" className="content-link">Neem contact op</Link>
                    </div>

                    <div className="container image image--1">
                        <Img fluid={data.featuredService1Image.childImageSharp.fluid} alt="" className="" />
                    </div>

                    <div className="container image image--2">
                        <Img fluid={data.featuredService2Image.childImageSharp.fluid} alt="" className="" />
                    </div>

                    <div className="feature content">
                        <h1>Salarisadministratie</h1>

                        <p className="feature__summary">Met onze software is het beheren van uw salarisadministratie nog nooit zo eenvoudig geweest. Wij ontlasten u ook graag met dit deel van uw administratie. Door het koppelen van uw salarisadministratie met het boekhoudprogramma wordt alles gestroomlijnd doorgevoerd.</p>

                        <Link to="/contact" className="content-link">Neem contact op</Link>
                    </div>

                    <div className="feature content">
                        <h1>Advies</h1>

                        <p className="feature__summary">Met ons kantoor neemt u een partner in handen die graag met u en uw onderneming meedenkt. Wij kunnen u fiscaal adviseren met begrotingen, prognoses, financieringsaanvragen, herstructureringen, verzekeringen, pensioen en nog veel meer complexe fiscale adviezen.</p>

                        <Link to="/contact" className="content-link">Neem contact op</Link>
                    </div>

                    <div className="container image image--3">
                        <Img fluid={data.featuredService3Image.childImageSharp.fluid} alt="" className="" />
                    </div>

                    <div className="container image image--4">
                        <Img fluid={data.featuredService4Image.childImageSharp.fluid} alt="" className="" />
                    </div>

                    <div className="feature content">
                        <h1>Begeleiding van Startende Ondernemers</h1>

                        <p className="feature__summary">Een bedrijf starten is een uitdagende en spannende periode waarbij een goede basis van essentieel belang is. Als uw partner kunnen wij u daarom begeleiden met het opzetten van uw onderneming, zoals de rechtsvorm keuze, KvK inschrijving, de juiste verzekeringen, pensioen, het opzetten van een correcte financi&euml;le administratie en eventuele investeringen.</p>

                        <Link to="/contact" className="content-link">Neem contact op</Link>
                    </div>
                </StyledOurFeatures>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default ZakelijkPagina;